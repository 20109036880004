import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';
import BestFriendsHugVideo from './assets/best-friends-hug.mp4';

const GladToHaveYouBack = ({ location }) => {
  const pageTitle = 'Glad to have you back on board!';
  return (
    <Layout pageTitle={pageTitle}>
      <Seo index={false} follow={false} pathname={location.pathname} />
      <Article>
        <h1 className="fancy">{pageTitle}</h1>
        <video
          autoPlay
          loop
          muted="muted"
          playsInline
          controlsList="nodownload"
          src={BestFriendsHugVideo}
          style={{ width: '100%', marginBottom: '1em' }}></video>
        <p>
          Thanks for deciding to stick around. Hey, while this is on your mind,
          hit “reply” on any email you’ve received from me and let me know your
          current biggest roadblock, worry, or fear about your career
          transition.
        </p>
        <p>
          Doesn’t matter whether you’re just getting started learning, dipping
          your toe into freelancing, or shotgunning applications like there’s no
          tomorrow. I’d love to help you get past whatever is holding you up no
          matter what stage you’re at.
        </p>
      </Article>
    </Layout>
  );
};
export default GladToHaveYouBack;
